body {
    padding: 0;
    margin: 0;
}

#root {
    display: flex;
    min-height: 100vh;
}

div {
    box-sizing: border-box;
}

.shot {
    position: absolute;
    width: 20px;
    height: 20px;
    opacity: 0.5;
    transform: translate(-50%, -50%);
}
.shot:hover {
    opacity: 1;
}
.shot:before, .shot:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #333;
}
.shot:before {
    transform: translate(-50%, -50%) rotate(45deg);
}
.shot:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.shot.goal {
    border: 1px solid #333333;
    border-radius: 10px;
}

.shot.goal:before,
.shot.goal:after {
    /*background-color: green;*/
}

.shot.onGoal:before,
.shot.onGoal:after {
    /*background-color: yellow;*/
}

.shot.blocked{
    height: 10px;
    width: 14px;
    border-top: 2px solid #333333;
    border-bottom: 2px solid #333333;
}
.shot.blocked:before{
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    transform: translateY(-50%) rotate(0);
}
.shot.blocked:after {
    display: none;
}

.shot.missed:before {
    text-align: center;
    content: 'V';
    width: 100%;
    background: none;
    transform: translate(-50%, -50%) rotate(0);
}
.shot.missed:after {
    display: none;
}

.court-cluster:before {
    position: absolute;
    width: 100%;
    height: calc(100% * 1 / 3 - 2px);
    top: calc(100% * 1 / 3);
    border: 1px solid gray;
    content: ' ';
}

.court-cluster:after {
    position: absolute;
    width: calc(50% - 1px);
    height: 100%;
    left: calc(50%);
    border-left: 1px solid gray;
    content: ' ';
}

.groundline {
    position: absolute;
    top: 0;
    height: 100%;
    left: 12%;
    border-left: 1px solid gray;
}

.numpad-container {
  /*height: 100%;*/
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
}
.numpad {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.numpad button {
  width: 100px;
  height: 100px;
  font-size: 30px;
}
